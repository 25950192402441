import clsx from "clsx";
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  header?: boolean;
  forwardedRef?: React.Ref<HTMLDivElement>;
}

function Row({
  children,
  style,
  className,
  header,
  onClick,
  forwardedRef,
  ...rest
}: Props) {
  return (
    <div
      style={style}
      ref={forwardedRef}
      className={clsx(
        "flex-row",
        header ? "flex-row-header" : "hover:bg-gray-100",
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Row {...props} forwardedRef={ref} />
));
