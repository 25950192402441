import clsx from "clsx";
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size?: string;
  forwardedRef?: React.Ref<HTMLDivElement>;
}

function Cell({
  children,
  size,
  style,
  className,
  forwardedRef,
  ...rest
}: Props) {
  const cellStyle = { ...(style || {}) };

  if (size) {
    cellStyle.flex = `0 0 ${size}px`;
  }

  return (
    <div
      {...rest}
      ref={forwardedRef}
      className={clsx("flex-cell", className)}
      style={cellStyle}
    >
      {children}
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Cell {...props} forwardedRef={ref} />
));
